import React, { useEffect, useRef, useState } from 'react'
import { graphql } from 'gatsby'
import { useSession } from 'src/sdk/session'
import { usePageViewEvent } from 'src/sdk/analytics/hooks/usePageViewEvent'
import { useCampaignContext } from 'src/contexts/campaign-context'
import GatsbySeoCustom from 'src/components/GatsbySeoCustom'
import { Image } from 'src/components/ui/Image'

import storeConfig from '../../store.config'

import './404.scss'

function Page() {
  const { setCampaignPage } = useCampaignContext()
  const { person } = useSession()
  const [didMount, setDidMount] = useState(false)
  const { sendPageViewEvent } = usePageViewEvent('404', person)

  const timerRef = useRef<NodeJS.Timeout | undefined>()

  setCampaignPage(false)

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      if (!didMount) {
        sendPageViewEvent()
        setDidMount(true)
      }
    }, 500)
  }, [person])

  const { account } = storeConfig

  return (
    <div className="error">
      <GatsbySeoCustom
        title="Vivara, Erro 404!"
        description="Error 400 page"
        language="pt-BR"
        noindex
        nofollow
      />
      <div className="error__img-container">
        <Image
          className="error__img"
          baseUrl={`http://${account}.vtexassets.com/arquivos/erro-404.png`}
          alt="Caixa Vazia"
          width={214}
          aspectRatio={0.89}
          layout="constrained"
          loading="lazy"
          options={{
            fitIn: true,
          }}
        />
      </div>
      <div className="error__title-container">
        <h3 className="error__title">OPS!</h3>
        <span className="error__span-1">
          Não encontramos o que você procurou.
        </span>

        <span className="error__span-2">
          Mas não desista! Temos um catálogo incrível de itens que podem te
          agradar. Que tal dar uma olhada?
        </span>

        <button
          className="error__btn-home"
          onClick={() => (window.location.href = '/')}
        >
          ir para home
        </button>
      </div>
    </div>
  )
}

export const query = graphql`
  query NotFoundPageQuery {
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

export default Page
